import * as THREE from 'three'
import { Material } from 'three'
import Experience from './Experience.js'
import MusicPlayer from './MusicPlayer'
import gsap from 'gsap'

//import fragment from './Shaders/lavaFragmentShader.glsl'
//import vertex from './Shaders/lavaVertexShader.glsl'


export default class World
{
    constructor(_options)
    {
        this.experience = new Experience()
        this.config = this.experience.config
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.renderer = this.experience.renderer.instance
        this.time = this.experience.time
        this.loader = new THREE.TextureLoader();
        this.MusicPlayer = new MusicPlayer();        

        this.addLights();

        this.resources.on('groupEnd', (_group) =>
        {
            if(_group.name === 'textures')
            {
                //this.addObjects()
                //this.animate()

                this.sortTextures()
            }
            if(_group.name === 'skull')
            {
                this.createCubeTexture()
                this.addSkull()
                this.addCube()
                this.addOuterCube()
                //this.addOuterSphere()                
            }            
        })

        this.track_hover = 1;
        this.list_items = document.querySelector(".menu");    
        this.list_items.addEventListener("mouseover", ()=> {
            
        } )

        this.list_items.addEventListener("mousedown", ()=> {
            this.changeSong(event);
            this.changeCube(event);
            this.activeLink(event);
        } )        
        
   
    }

    activeLink(event)
    {
        var listItems = document.querySelectorAll('.menu li a');
        listItems.forEach(function(listItem) {
            listItem.classList.remove("active");
          });
        event.target.classList.add("active");
    }

    sortTextures()
    {

        this.textures = [this.resources.items.bw, this.resources.items.sunburn, this.resources.items.lp, this.resources.items.hb, this.resources.items.jn]

        this.textures.forEach(sortWrapping);

        function sortWrapping(texture) {
            texture.wrapS = THREE.RepeatWrapping; 
            texture.wrapT = THREE.RepeatWrapping;
            texture.repeat.set( 1, 1 ); 
            texture.repeat.set( 1, 1 ); 
        }

    }

    addOuterCube() {

        const geometry = new THREE.BoxGeometry( 10, 4, 10, 10, 10, 10 );
        const material = new THREE.MeshStandardMaterial( {
            side: THREE.BackSide,
            color: 0xff0000,
        } );
     
        this.outerCube = new THREE.Mesh( geometry, material );
        this.outerCube.receiveShadow = true
        this.scene.add( this.outerCube );        
    }

    createCubeTexture() {
        this.cubeRenderTarget = new THREE.WebGLCubeRenderTarget( 128, { 
            generateMipmaps: true, 
            minFilter: THREE.LinearMipmapLinearFilter 
        } );        
        // Create cube camera
        this.cubeCamera = new THREE.CubeCamera( 1, 100000, this.cubeRenderTarget );
        this.scene.add( this.cubeCamera );
    }

    addSkull()
    {
        this.skull = this.resources.items.human_skull;
        this.skull.scene.scale.set(3,3,3);
        this.scene.add(this.skull.scene);

        // mobile
        const mediaQuery = window.matchMedia('(max-width: 768px)')
        if (mediaQuery.matches) {
            this.skull.scene.scale.set(1.5,1.5,1.5);
        }
    }
    
    addCube()
    {

        this.cubeColors = [ "0xff0000", "0xff3333", "0xDD6500", "0x4d00aa", "0x008080"]
        const geometry = new THREE.BoxGeometry( 1, 1, 1, 10, 10, 10 );
        const material = new THREE.MeshPhysicalMaterial( {
            side: THREE.DoubleSide,
            color: 0xff0000,
            emissive: 0x111111,
            metalness: 0,
            roughness: 0.15,
            reflectivity: 0.8,
            clearcoat: 0.5,
            clearcoatRoughness: 1,
            transmission: 0.7,
            transparent: true,
            ior: 2.3,
            thickness: 0.02,
            //map: this.cubeRenderTarget.texture,
            clearcoatNormalMap: this.textures[0],
            emissiveMap: this.textures[0],
            emissiveIntensity: 5
        } );
     
        this.cube = new THREE.Mesh( geometry, material )
        this.cube.castShadow = true
        this.cube.animated = false
        this.scene.add( this.cube )

        // mobile
        const mediaQuery = window.matchMedia('(max-width: 768px)')
        if (mediaQuery.matches) {
            this.cube.scale.set(0.5,0.5,0.5);
        }
   
    }

    addOuterSphere()
    {
        this.texture = this.resources.items.test_bg;
        const geometry = new THREE.SphereGeometry( 15, 32, 16 );
        const material = new THREE.MeshBasicMaterial( { 
            color: 0xffffff,
            side: THREE.DoubleSide,
            map: this.texture
        } );
        this.sphere = new THREE.Mesh( geometry, material );
        //this.sphere.visible = false
        this.scene.add( this.sphere );

   
    }    

 
    addLights()
    {
    // AMBIENT LIGHT
    this.scene.add(new THREE.AmbientLight(0xffffff, 2));
    // DIRECTIONAL LIGHT
    const dirLight = new THREE.DirectionalLight(0xffffff, 1)
    dirLight.position.x += 20
    dirLight.position.y += 20
    dirLight.position.z += 20
    dirLight.castShadow = true
    dirLight.shadow.mapSize.width = 4096;
    dirLight.shadow.mapSize.height = 4096;
    const d = 10;
    dirLight.shadow.camera.left = - d;
    dirLight.shadow.camera.right = d;
    dirLight.shadow.camera.top = d;
    dirLight.shadow.camera.bottom = - d;
    dirLight.position.z = -125;

    let target = new THREE.Object3D();
    target.position.z = 0;
    dirLight.target = target;
    dirLight.target.updateMatrixWorld();

    
    this.scene.add(dirLight);
    }

    changeCube(event)
    {
       if(event.target.dataset.track !== undefined){
        this.track_hover = event.target.dataset.track
        this.cubeChangeColour(this.track_hover)
        this.cubeChangeTexture(this.track_hover)
       }

       
    }

    changeSong(event)
    {
       if(event.target.dataset.track !== undefined){
        this.track_hover = event.target.dataset.track
        this.MusicPlayer.loadSong(this.MusicPlayer.songs[this.track_hover]);
        this.MusicPlayer.playSong();
       }

       
    }    

    cubeChangeColour(track)
    {        

        /*
        this.cube.material.color.setHex(this.cubeColors[track]);
        this.outerCube.material.color.setHex(this.cubeColors[track]);
        console.log(this.cubeColors[track])
        */

        var initial = new THREE.Color(this.cube.material.color.getHex());
        var value = new THREE.Color().setHex(this.cubeColors[track]);

        gsap.to(initial, 1, {
          r: value.r,
          g: value.g,
          b: value.b,
          ease: "sine.out",
          onUpdate: () => {
            this.cube.material.color = initial;
            this.outerCube.material.color = initial;
          }
        });
   

    }

    cubeChangeTexture(track)
    {

        this.cube.material.clearcoatNormalMap = this.textures[track];
        this.cube.material.emissiveMap = this.textures[track];
        console.log(this.cubeColors[track])

    }    

    updateCubes(trackIndex){
        this.cubeChangeTexture(trackIndex);
        this.cubeChangeColour(trackIndex);
    }


    resize()
    {
    }

    update()
    {
   
        if(this.cubeCamera) {
            this.cubeCamera.update( this.renderer, this.scene );
        }

        if(this.cube) {
            if(this.cube.animated == true) {
                this.cube.rotation.y += 0.005
                this.skull.scene.rotation.y += 0.005
            }
            
        }

    }

    destroy()
    {
    }
}