export default [
    {
        name: 'textures',
        data: {},
        items:
        [
            { name: 'test_bg', source: '/assets/test-bg-2.jpg', type: 'texture' },
            { name: 'sunburn', source: '/assets/sunburn.png', type: 'texture' },
            { name: 'bw', source: '/assets/bw.png', type: 'texture' },
            { name: 'lp', source: '/assets/lp.png', type: 'texture' },
            { name: 'hb', source: '/assets/hb.png', type: 'texture' },
            { name: 'jn', source: '/assets/jn.png', type: 'texture' },
        ]
    },
    {
        name: 'skull',
        data: {},
        items:
        [
            { name: 'human_skull', source: '/assets/human_skull/scene.gltf', type: 'gltf' },

        ]
    }    
]