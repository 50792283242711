import Experience from './Experience.js'

export default class MusicPlayer
{
    constructor()
    {
        
        
        this.experience = new Experience()

        this.musicContainer = document.getElementById('music-container');
        this.playBtn = document.getElementById('play');
        this.prevBtn = document.getElementById('prev');
        this.nextBtn = document.getElementById('next');

        this.audio = document.getElementById('audio');
        this.progress = document.getElementById('progress');
        this.progressContainer = document.getElementById('progress-container');
        //this.title = document.getElementById('title');
        //this.cover = document.getElementById('cover');
        this.currTime = document.querySelector('#currTime');
        this.durTime = document.querySelector('#durTime');

        this.songs = [
            'bw',
            'sunburn',
            'lp',
            'hb',
            'jn'
        ];
        
        this.songIndex = 0;

        this.loadSong(this.songs[this.songIndex]);

        this.playBtn.addEventListener('click', () => {
            this.isPlaying = this.musicContainer.classList.contains('play');
          
            if (this.isPlaying) {
              this.pauseSong();
            } else {
              this.playSong();
            }
          });

        this.prevBtn.addEventListener('click', () => { this.prevSong() });
        this.nextBtn.addEventListener('click', () => { this.nextSong() });  
        this.audio.addEventListener('timeupdate', (e) => { this.updateProgress(e) }); 
        this.progressContainer.addEventListener('click', (e) => { this.setProgress(e) });

    }

    loadSong(song) {
        //this.title.innerText = song;
        this.audio.src = '/assets/' + song +'.mp3';
        //cover.src = `images/${song}.jpg`;
    }

    playSong() {
        this.musicContainer.classList.add('play');
        //this.playBtn.querySelector('i.fas').classList.remove('fa-play');
        //this.playBtn.querySelector('i.fas').classList.add('fa-pause');
        this.playBtn.innerHTML = "pause";
      
        this.audio.play();
        this.experience.world.cube.animated = true;
    }   
    
    pauseSong() {
        this.musicContainer.classList.remove('play');
        //this.playBtn.querySelector('i.fas').classList.add('fa-play');
        //this.playBtn.querySelector('i.fas').classList.remove('fa-pause');
        this.playBtn.innerHTML = "play";
      
        this.audio.pause();
        this.experience.world.cube.animated = false;
    }   
    
    prevSong() {
        this.songIndex--;
    
        if (this.songIndex < 0) {
            this.songIndex = this.songs.length - 1;
        }
    
        this.loadSong(this.songs[this.songIndex]);
    
        this.playSong();

        this.experience.world.updateCubes(this.songIndex);
    } 
    
    nextSong() {
        this.songIndex++;
      
        if (this.songIndex > this.songs.length - 1) {
            this.songIndex = 0;
        }
      
        this.loadSong(this.songs[this.songIndex]);
      
        this.playSong();
        this.experience.world.updateCubes(this.songIndex);
      }    

      updateProgress(e) {
        const { duration, currentTime } = e.srcElement;
        const progressPercent = (currentTime / duration) * 100;
        progress.style.width = `${progressPercent}%`;
      } 
      
      setProgress(e) {
        const width = this.clientWidth;
        const clickX = e.offsetX;
        const duration = this.audio.duration;
      
        this.audio.currentTime = (clickX / width) * duration;
      }      

}